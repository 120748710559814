<template>

  <section id="inscripciones">
    <div class="row">
      <div class="col-12">
        <h3 class="section-head-title ps-0 my-3">
          En esta seccion podrás solicitar o cancelar solicitudes para tus colaboradores.
        </h3>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <router-view />
      </div>
    </div>
  </section>

</template>

<script>

export default {

  data() {
    return {
      
    };
  },
}
</script>